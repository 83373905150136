import React from "react";

export default function Loading({height}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: height,
      }}
    >
      <br></br>
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
