import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import PriceGraph from "./PriceGraph";
import Loading from "./Loading";
import { ApiContext } from "../ApiContext";

const VAT_PERCENTAGE = require("spot-automation-common").VAT_PERCENTAGE

//FIXME: this is based on the current locale and will most probably not work on different timezones

function renderPriceInfo(prices) {
  // By default the first hour is present
  if (prices && prices.length > 1) {
    // Why on earth would the default sorting be done for string representation of the number? 
    // Anyways, do manual comparator
    const sortedPrices = prices.sort((a, b) => a > b)
    const minPrice = sortedPrices.slice(0, 1).map(it => it.toFixed(2))
    const maxPrice = sortedPrices.slice(-1).map(it => it.toFixed(2))
    let averagePrice = 0
    sortedPrices.forEach(it => { averagePrice += it })
    averagePrice = (averagePrice / sortedPrices.length).toFixed(2)
    const medianStart = Math.floor(sortedPrices.length / 2)
    console.log(medianStart)
    const medianPrices = sortedPrices.slice(medianStart, medianStart + 2)
    console.log("Median prices", medianPrices)
    let medianPrice = 0
    medianPrices.forEach(it => { medianPrice += it })
    medianPrice = (medianPrice / medianPrices.length).toFixed(2)
    return <div style={{ display: "flex", flexDirection: "row", gap: "2em" }}>
      <p>Alin <b>{minPrice}</b> </p><p>Ylin <b>{maxPrice}</b></p><p> Keskiarvo <b>{averagePrice}</b></p><p> Mediaani <b>{medianPrice}</b></p>
    </div>
  }
  else {
    const update = new Date()
    update.setUTCHours(12)
    update.setMinutes(15)
    update.setSeconds(0)
    return <>
      <p>Huomisen hinnat päivittyvät sivulle klo {update.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}</p>
    </>
  }
}


function Prices() {
  const { prices } = useContext(ApiContext)
  const title = "Pörssisähkön spot-hinta"
  const description = "Pörssisähkön spot-hinta Suomessa tänään ja huomenna"
  let nowPrice = 0
  let content = <></>
  if (prices) {
    const now = new Date();
    const tomorrow = new Date()
    tomorrow.setDate(now.getDate() + 1)
    let nowIndex = 0;
    for (let i = 0; i < prices.length; i++) {
      const date = prices[i].date;
      if (
        now.getFullYear() === date.getFullYear() &&
        now.getMonth() === date.getMonth() &&
        now.getDate() === date.getDate() &&
        now.getHours() === date.getHours()
      ) {
        nowIndex = i;
      }
    }
    nowPrice = prices[nowIndex].price.toFixed(2)
    const todayPrices = prices
      .filter(it => now.getDate() === it.date.getDate())
      .map(it => it.price)
    console.log("Today prices", todayPrices)
    const tomorrowPrices = prices
      .filter(it => tomorrow.getDate() === it.date.getDate())
      .map(it => it.price)
    console.log("Tomorrow prices", tomorrowPrices)

    const details = <>
      <div>
        <h3 style={{ marginBottom: "0" }}>Tänään</h3>
        {renderPriceInfo(todayPrices)}
        <h3 style={{ marginBottom: "0" }}>Huomenna</h3>
        {renderPriceInfo(tomorrowPrices)}
      </div>
    </>
    content =
      <>
        <p>Hinta nyt <b>{nowPrice}</b> snt/kWh (sis. ALV {VAT_PERCENTAGE} %)</p>
        {details}
        <div style={{ marginTop: "3em" }}>
          <PriceGraph prices={prices} showTicks={true} showDate={true} showPoints={false} />
        </div>
      </>
  } else {
    content = <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>

      <div className="main-content">
        <div style={{ display: "flex", flexDirection: "column", width: "85vw", maxWidth: "50em" }}>
          <h2>{title}</h2>
          {content}
        </div>
      </div>
    </>
  )
}

export default Prices;
