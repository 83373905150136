import React, { useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ApiContext } from "../ApiContext";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";

function Login() {
  const navigate = useNavigate();

  const { ApiCall, set_user } = useContext(ApiContext);
  const { value } = useParams();
  const [email, set_email] = useState(value);
  const [password, set_password] = useState("");

  function handleSubmit(event) {
    event.preventDefault();
    const body = {
      email: email,
      password: password,
    };
    ApiCall("POST", "user/login", body)
      .then((res) => {
        document.cookie = "token=" + res.token;
        set_user(body.email);
        navigate("/device");
      })
      .catch((err) => {
        console.error(err);
        if (err.response.status === 400) {
          toast.error("Sähköposti ei ole käytössä")
        } else if (err.response.status === 403) {
          toast.error("Väärä salasana");
        } else {
          toast.error("Jotain odottamatonta meni pieleen");
        }
      });
  }

  return (
    <div className="main-content">
      <Toaster />
      <div className="input-container">
        <div>
          <h1>Kirjaudu sisään</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            autoFocus={true}
            required={true}
            placeholder="Syötä sähköpostiosoitteesi"
            value={email}
            onChange={(e) => {
              set_email(e.target.value);
            }}
          ></input>
          <input
            type="password"
            required={true}
            minLength={8}
            placeholder="Syötä salasanasi"
            onChange={(e) => {
              set_password(e.target.value);
            }}
          ></input>
          <br></br>
          <input
            type="submit"
            value="Kirjaudu sisään"
            className="basic-button"
          />
        </form>
        <Link className="link" to="/forgot">
          Unohditko salasanasi?
        </Link>
        <Link className="link" to="/register">
          Rekisteröidy
        </Link>
      </div>
    </div>
  );
}

export default Login;
