
export function calculateSavingsToday(todayPrices, activity) {
    // Calculate "average savings" when compared to median/max prices for today for default config with 11 kW
    const now = new Date()
    const activityToday = activity.filter(it => it.date.getDate() === now.getDate())
    const activehoursToday = activityToday.length
    const todayPricesDecreasing = todayPrices.map(i => i).sort((a, b) => b.price - a.price)
    const medianIndex = Math.floor(todayPricesDecreasing.length / 2)
    const sliceStart = medianIndex - Math.ceil(activehoursToday / 2)
    const sliceEnd = medianIndex + Math.floor(activehoursToday / 2)
    const medianPricesTodayForActiveHours = todayPricesDecreasing.slice(sliceStart, sliceEnd)
    const maxPricesTodayForActiveHours = todayPricesDecreasing.slice(0, activehoursToday)
    const power = 11 //kW
    let totalActivityPrice = 0
    activityToday.map(act => act.price).forEach(price => {
        totalActivityPrice += price * power
    })
    console.log("Prices today", todayPricesDecreasing)
    console.log("Activity today", activityToday)
    console.log("Median prices for todays activity", medianPricesTodayForActiveHours)
    console.log("Max prices for todays activity", maxPricesTodayForActiveHours)
    let medianTotalPrice = 0
    medianPricesTodayForActiveHours.map(it => it.price).forEach(price => {
        medianTotalPrice += price * power
    })
    let maxTotalPrice = 0
    maxPricesTodayForActiveHours.map(it => it.price).forEach(price => {
        maxTotalPrice += price * power
    })
    console.log("Prices median total", medianTotalPrice, totalActivityPrice)
    const medianSavings = medianTotalPrice - totalActivityPrice
    const maxSavings = maxTotalPrice - totalActivityPrice
    console.log("New savings today", medianSavings, maxSavings)
    return {
        median: medianSavings,
        max: maxSavings,
    }
}