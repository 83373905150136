import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../assets/icons/arrow-left.svg";
import { ReactComponent as Gear } from "../assets/icons/gear.svg";
import { ReactComponent as Connection } from "../assets/icons/connection.svg";
import DeviceStatus from "./DeviceStatus";
import DeviceLog from "./DeviceLog";
import placeholderPng from "../assets/images/kajokaara-small.jpeg";
import placeholder from "../assets/images/kajokaara-small.webp";
import DeviceSettings from "./DeviceSettings";
import DeviceOptions from "./DeviceOptions.js";
import { ApiContext } from "../ApiContext";
import Loading from "./Loading";
import "./Device.css";
import OcppOptions from "./OcppOptions";

function Config() {

  const title = "Laitteen tiedot"
  const description = "Tarkastele laitteen tilaa ja hallinnoi asetuksia"


  const { ApiCall } = useContext(ApiContext);
  const navigate = useNavigate();

  const { id } = useParams();
  const [device, setDevice] = useState();
  const [config, setConfig] = useState();
  const { value } = useParams();
  const [show_options, set_show_options] = useState(value === "options");
  const [show_ocpp, set_show_ocpp] = useState(value === "ocpp");

  const DEFAULT_DEVICE_CONFIG = require("spot-automation-common").DEFAULT_DEVICE_CONFIG

  useEffect(() => {
    ApiCall("GET", "device/" + id)
      .then((res) => {
        console.log(res)
        setDevice(res)
      })
      .catch((err) => {
        console.error(err)
      });
    ApiCall("GET", "device/config/active/" + id)
      .then((res) => {
        console.log(res)
        setConfig(res)
      })
      .catch((err) => {
        console.error(err)
        //Default values to use if config is not yet created
        setConfig(DEFAULT_DEVICE_CONFIG)
      });
  }, [id, ApiCall])

  function closeOptions() {
    set_show_options(false);
    set_show_ocpp(false);
    // remove param when link to ocpp or device settings
    navigate("/device/" + device.mac)
  }
  if (!device || !config) {
    return <Loading />;
  } else {
    return (
      <>
        <Helmet>
          <title>Kajo Spotti - {title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
        </Helmet>
        <div className="main-content">
          <div className="container">
            <div
              style={{
                width: "100%",
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <button onClick={() => navigate("/device")} className="circle-button">
                  {/* shitty svg >:( */}
                  <ArrowLeft />
                </button>

                <picture >
                  <source srcSet={placeholder} />
                  <img src={placeholderPng} className="device-header-image" alt="Laitteen kuva" />
                </picture>
                <h3 style={{ marginLeft: "1em", maxWidth: "50px" }}>
                  {device && device.displayName}
                </h3>
              </div>
              <div style={{ display: "flex" }}>
                <button
                  onClick={() => set_show_options(true)}
                  className="circle-button"
                  style={{ marginRight: "1em" }}
                >
                  <Gear />
                </button>
                <button
                  onClick={() => set_show_ocpp(true)}
                  className="circle-button"
                >
                  <Connection />
                </button>
              </div>
            </div>
            <div style={{ width: "100%", marginTop: "0.5em" }}>
              <div className="device-info">
                <DeviceStatus config={config} showTicks={false} details={true} />
              </div>
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              <DeviceSettings
                device={device}
                config={config}
                setConfig={setConfig}
                enableSubmit={true}
              />
              <DeviceLog device={device} />
            </div>
            {show_options && (
              <DeviceOptions
                device={device}
                setDevice={setDevice}
                closeOptions={closeOptions}
              />
            )}
            {show_ocpp && (
              <OcppOptions
                device={device}
                setDevice={setDevice}
                closeOptions={closeOptions}
              />
            )}
          </div>
        </div >
      </>
    );
  }
}

export default Config;
