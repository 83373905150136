import { Outlet, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import Header from "./Header";
import { ApiProvider } from "../ApiContext";
const title = "Kajo Spotti - Optimoi sähkönkulutuksesi";
const shortTitle = "Kajo Spotti";
const description = "Optimoi pörssisähkön kulutuksesi ja säästä sähkölaskussa helposti ajastamalla kulutus halvimpaan aikaan";

import ReactGA from "react-ga4";
import { useEffect } from "react";

function Root() {
    //TODO: test id in version control. This is a public key anyways?
    ReactGA.initialize("G-C2E8KNFHVY")
    const location = useLocation()
    useEffect(() => {
        console.log("Hellou page", location)
        ReactGA.send({ hitType: "pageview", page: location.pathname })
    }, [location])
    return (
        <ApiProvider ga4={ReactGA}>
            <div className="root-container">
                <Header />
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                </Helmet>
                <Outlet />
                <div className="spacer"></div>
                <Footer />
            </div>
        </ApiProvider>
    )
}
export { title, shortTitle, description, Root }