const VAT_PERCENTAGE = 25.5

const DEFAULT_DEVICE_CONFIG = {
    maxPrice: 25,
    numberOfHours: 6,
    firstHour: 22,
    lastHour: 7,
    consecutive: false,
    mode: "automatic"
}

exports.VAT_PERCENTAGE = VAT_PERCENTAGE;
exports.DEFAULT_DEVICE_CONFIG = DEFAULT_DEVICE_CONFIG;