import React, { useContext, useState } from "react";
import { ApiContext } from "../ApiContext";
import { ReactComponent as Connection } from "../assets/icons/connection.svg";
import { motion } from "framer-motion";
import "./Device.css";
import ChargerSelection from "./ChargerSelection";
import { Link } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { popupEasing } from "../utils/Easing";

function OcppOptions({ closeOptions, device, setDevice }) {
    const { ApiCall, ga4 } = useContext(ApiContext);
    const [id, setId] = useState(device.subscription.id);
    const [selectedCharger, setSelectedCharger] = useState(device.chargerType)
    const [selectedGuide, setSelectedGuide] = useState(device.SupportedCharger?.guide)
    function handleSubmit(event) {
        event.preventDefault();
        if (!hasChanges()) {
            return
        }
        ApiCall("PATCH", `device/subscription/${device.mac}`, {
            id: id
        }).then((res) => {
            ApiCall("POST", "device", {
                mac: device.mac,
                chargerType: selectedCharger,
            }).then(device => {
                console.log("Saved device state", device)
                closeOptions()
                setDevice(device)
                toast.success("Asetukset tallennettu")
                ga4.event({
                    category: "device",
                    action: "change_ocpp_setttings",
                    label: "Change device ocpp settings",
                })
            }).catch(err => {
                console.error(err)
                toast.error("Jotain odottamatonta meni pieleen.")
            })
        }).catch((err) => {
            console.error(err)
            toast.error("Latausaseman tunniste on jo käytössä. Tarkista tunniste.")
        });
    }

    function hasChanges() {
        return id !== device.subscription.id || device.chargerType !== selectedCharger
    }

    return (
        <motion.nav
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ease: "linear", duration: 0.2 }}
            className="device-setup-nav"
        >
            <Toaster />
            <div onClick={() => closeOptions()} className="device-setup-container">
                <motion.nav
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ ease: popupEasing, duration: 0.2 }}
                    className="device-setup-nav"
                >
                    <div onClick={(e) => e.stopPropagation()} className="popup-container input-container" >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <h2>
                                OCPP-asetukset
                            </h2>
                            <button className="circle-button" onClick={() => closeOptions()} style={{ marginLeft: "1em" }}>
                                <Connection />
                            </button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <label>Latausaseman tyyppi</label>
                            <ChargerSelection selectedCharger={selectedCharger} setSelectedCharger={setSelectedCharger} selectedGuide={selectedGuide} setSelectedGuide={(guide) => setSelectedGuide(guide)} />
                            <div style={{ marginBottom: "1.5em", alignContent: "start", display: "flex", justifyContent: "start", width: "100%", flexDirection: "column" }}>
                                {selectedGuide && <Link className="link" style={{ margin: "0px", marginBottom: "0.5em" }} to={`${process.env.PUBLIC_URL}/ocpp/guides/${selectedGuide}`} target="_blank" rel="noreferrer">Avaa ohjeet konfigurointiin</Link>}
                                {/*FIXME: better way to do type specific info */}
                                {selectedGuide === "kajo-abb-ocpp.pdf" && <><p>TerraConfig käyttäjätunnus:</p><p>operator@kajosolutions.com</p><p>TerraConfig salasana:</p><p>KajoSolutions</p></>}
                                {!selectedGuide && <>
                                    <p> Latausasemallesi ei ole tarkkoja ohjeita.</p>
                                    <Link className="link" style={{ margin: "0px", marginBottom: "0.5em" }} to={`${process.env.PUBLIC_URL}/ocpp/guides/kajo-generic-ocpp.pdf`} target="_blank" rel="noreferrer">Avaa yleiset ohjeet konfigurointiin</Link>
                                    <p>Ota yhteyttä sähköpostilla <Link to="mailto:info@kajosolutions.com?subject=Uuden latausaseman lisäys&Body=Hei,%0D%0Ahaluaisin tuen latausasemalleni">info@kajosolutions.com</Link> ja auta meitä lisäämään ohjeet latausasemallesi.</p>
                                </>
                                }
                            </div>
                            <label>Latausaseman tunniste</label>
                            <input type="text" value={id} placeholder="Latausaseman tunniste" required={true} onChange={(e) => setId(e.target.value)} />
                            <p>Latausasemasi tunnisteen löydät konfigurointiohjeiden avulla.</p>
                            <input
                                type="submit"
                                value="Tallenna"
                                className={hasChanges() ? "basic-button" : "basic-button disabled"}
                            />
                        </form>

                    </div>
                </motion.nav>
            </div >
        </motion.nav >
    );
}

export default OcppOptions;
