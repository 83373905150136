import { useNavigate } from "react-router-dom";
import React from "react";
import { Link } from "react-router-dom";
function PrivacyPolicy() {
    const navigate = useNavigate();
    return (
        <div className="main-content">
            <div className="popup-container">
                <h2 style={{ alignSelf: "flex-start", margin: 0 }}>
                    Rekisteri- ja tietosuojaseloste
                </h2>
                <p>Tämä on Kajo Solutions Oy EU:n yleisen tietosuoja-asetuksen (GDPR) mukainen rekisteri- ja tietosuojaseloste. Laadittu 02.09.2024. Viimeisin muutos 02.09.2024.</p>
                <h3>1. Rekisterinpitäjä</h3>
                <p>Kajo Solutions Oy, Sirritie 10, 39160 Julkujärvi</p>
                <h3>2. Rekisteristä vastaava yhteyshenkilö</h3>
                <p>Jaakko Seppälä, info@kajosolutions.com, 0404119545</p>
                <h3>3. Rekisterin nimi</h3>
                <p>Kajo Solutions Oy verkkopalvelun käyttäjärekisteri.</p>
                <h3>4. Oikeusperuste ja henkilötietojen käsittelyn tarkoitus</h3>
                <p>EU:n yleisen tietosuoja-asetuksen mukainen oikeusperuste henkilötietojen käsittelylle on henkilön suostumus.</p>
                <p>Henkilötietojen käsittelyn tarkoitus on verkkopalvelun käyttäjätunnukset.</p>
                <p>Tietoja ei käytetä automatisoituun päätöksentekoon tai profilointiin. </p>
                <h3>5. Rekisterin tietosisältö</h3>
                <p>Rekisteriin tallennettavia tietoja ovat: sähköpostiosoite, latausaseman tunniste, nimi ja lähettämät tiedot, www-sivustojen osoitteet, verkkoyhteyden IP-osoite, tiedot tilatuista palveluista ja niiden muutoksista ja laskutustiedot.</p>
                <p>Verkkosivuston vierailijoiden IP-osoitteita ja palvelun toiminnoille välttämättömiä evästeitä käsitellään oikeutetun edun perusteella mm. tietoturvasta huolehtimiseksi ja sivuston vierailijoiden tilastotietojen keruuta varten niissä tapauksissa, kun niiden voidaan katsoa olevan henkilötietoja. Kolmansien osapuolten evästeille pyydetään tarvittaessa suostumus erikseen.</p>
                <h3>6. Säännönmukaiset tietolähteet</h3>
                <p>Rekisteriin tallennettavat tiedot saadaan asiakkaalta mm. www-lomakkeilla lähetetyistä viesteistä, latausaseman rekisteröinnistä, sähköpostitse, puhelimitse, sosiaalisen median palvelujen kautta, sopimuksista, asiakastapaamisista ja muista tilanteista, joissa asiakas luovuttaa tietojaan.</p>
                <p>Yritysten ja muiden organisaatioiden yhteyshenkilöiden tietoja voidaan kerätä myös julkisista lähteistä kuten verkkosivuilta, hakemistopalveluista ja muilta yrityksiltä.</p>
                <h3>7. Tietojen säännönmukaiset luovutukset ja tietojen siirto EU:n tai ETA:n ulkopuolelle</h3>
                <p>Tietoja ei luovuteta muille tahoille.</p>
                <p>Tietoja ei siirretä rekisterinpitäjän toimesta EU:n tai ETA:n ulkopuolelle.</p>
                <h3>8. Rekisterin suojauksen periaatteet</h3>
                <p>Rekisterin käsittelyssä noudatetaan huolellisuutta ja tietojärjestelmien avulla käsiteltävät tiedot suojataan asianmukaisesti.
                    Kun rekisteritietoja säilytetään Internet-palvelimilla, niiden laitteiston fyysisestä ja digitaalisesta tietoturvasta huolehditaan asiaankuuluvasti.
                    Rekisterinpitäjä huolehtii siitä, että tallennettuja tietoja sekä palvelimien käyttöoikeuksia ja muita henkilötietojen turvallisuuden kannalta kriittisiä tietoja käsitellään luottamuksellisesti ja vain niiden työntekijöiden toimesta, joiden työnkuvaan se kuuluu.</p>
                <h3>9. Tarkastusoikeus ja oikeus vaatia tiedon korjaamista</h3>
                <p>Jokaisella rekisterissä olevalla henkilöllä on oikeus tarkistaa rekisteriin tallennetut tietonsa ja vaatia mahdollisen virheellisen tiedon korjaamista tai puutteellisen tiedon täydentämistä.
                    Mikäli henkilö haluaa tarkistaa hänestä tallennetut tiedot tai vaatia niihin oikaisua, pyyntö tulee lähettää kirjallisesti rekisterinpitäjälle.
                    Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan henkilöllisyytensä.
                    Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).</p>
                <h3>10. Muut henkilötietojen käsittelyyn liittyvät oikeudet</h3>
                <p>Rekisterissä olevalla henkilöllä on oikeus pyytää häntä koskevien henkilötietojen poistamiseen rekisteristä ("oikeus tulla unohdetuksi").
                    Niin ikään rekisteröidyillä on <Link to="http://eur-lex.europa.eu/legal-content/FI/TXT/?uri=CELEX%3A32016R0679#d1e2144-1-1" >muut EU:n yleisen tietosuoja-asetuksen mukaiset oikeudet</Link> kuten henkilötietojen käsittelyn rajoittaminen tietyissä tilanteissa.
                    Pyynnöt tulee lähettää kirjallisesti rekisterinpitäjälle. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan henkilöllisyytensä.
                    Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).</p>
                <button className="basic-button" onClick={(e) => navigate(-1)}>Takaisin</button>
            </div>
        </div>
    );
}
export default PrivacyPolicy;
