import { React, useContext, useEffect, useState } from "react"
import { ApiContext } from "../ApiContext"
import register from "../assets/images/register.svg"
import cheapestHours from "../assets/images/cheapest-hours.svg"
import safe from "../assets/images/safe.svg"
import timing from "../assets/images/timing.svg"

import "./Landing.css"
import { Link, useNavigate } from "react-router-dom"
import { HashLink } from "react-router-hash-link"
import { shortTitle, description } from "./Root";
import ChargerSelection from "./ChargerSelection"
import { DEFAULT_DEVICE_CONFIG, VAT_PERCENTAGE } from "spot-automation-common"
import { calculateSavingsToday } from "../utils/SavingsCalculation"

function GetStarted() {
    const { ApiCall, user, ga4 } = useContext(ApiContext);
    const [email, setEmail] = useState("");
    const navigate = useNavigate();

    function handleSubmit(event) {
        event.preventDefault();
        ga4.event({
            category: "user",
            action: "get_started",
            label: "User submitted get started",
        })
        ApiCall("POST", "user/welcome", { email: email })
            .then((res) => {
                console.log(res)
                if (res && res.new) {
                    navigate("/register/" + email);
                }
                else {
                    navigate("/login/" + email);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }
    return (
        <div className="get-started-container">
            <form onSubmit={handleSubmit} className="get-started-form">
                <h3 className="get-started-title">Kokeile nyt ilmaiseksi</h3>
                <p className="get-started-sub-title"> Tarvitset vain sähköauton, pörssisähkösopimuksen ja internet-yhteydellisen latausaseman</p>
                <div className="get-started-input-container">
                    {!user && <input type="email" className="get-started-input" required={true} placeholder="Syötä sähköpostiosoitteesi" style={{ backgroundColor: "white" }}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }} />}
                    {!user && <input type="submit" value="Jatka" className="basic-button get-started-button" style={{ height: "50px" }} />}
                    {user && <button className="basic-button get-started-button" style={{ marginBottom: "80px" }}
                        onClick={e => {
                            e.preventDefault();
                            navigate("/device");
                        }}>Hallitse laitteitasi</button >}
                </div>
            </form >

        </div>
    )
}

function TodayStats() {
    const { ApiCall, todayPrices } = useContext(ApiContext)
    const [activity, setActivity] = useState()
    const [savings, setSavings] = useState()
    const [priceNow, setPriceNow] = useState()

    useEffect(() => {
        if (!todayPrices) {
            return
        }
        else {
            if (!priceNow) {
                const now = new Date()
                let price = 0
                todayPrices.forEach(it => {
                    if (now.getHours() == it.date.getHours()) {
                        price = it.price
                    }
                })
                setPriceNow(price)
            }
        }
        if (!activity) {
            ApiCall("POST", "device/usage", DEFAULT_DEVICE_CONFIG).then((res) => {
                const act = res.map(it => ({
                    date: new Date(it.date),
                    price: (it.price * (100 + VAT_PERCENTAGE))
                }))
                act.sort((a, b) => a.date.getTime() - b.date.getTime())
                setActivity(act)
            }).catch((err) => {
                console.error(err)
            })
            return
        }
        if (!savings) {
            setSavings(calculateSavingsToday(todayPrices, activity))
            return
        }
        console.log("Stats today", priceNow, savings)
    }, [todayPrices, activity, savings])
    console.log("savings", savings)
    const shouldShowPriceStats = priceNow && savings && savings.max >= 50
    return <>
        <div className="today-stats">
            <div className={shouldShowPriceStats ? "today-stats-container visible" : "today-stats-container"}>
                <p className="today-stats-title">Sähkön hinta nyt</p>
                <p className="today-stat">{priceNow ? priceNow.toFixed(0) : 0} snt</p>
            </div>
            <div className={shouldShowPriceStats ? "today-stats-container visible" : "today-stats-container"} style={{ textAlign: "end" }}>
                <p className="today-stats-title">Säästö tänään jopa</p>
                <p className="today-stat">{savings ? (savings?.median / 100).toFixed(1) : 0} - {savings ? (savings?.max / 100).toFixed(1) : 0} €</p>
            </div>
        </div>
    </>
}

function KeyPoint({ icon, title, description }) {
    return (
        <div className="key-point">
            <img src={icon} className="key-img" alt="kuvituskuva" />
            <div className="key-text">
                <h3 className="key-title">{title}</h3>
                <p className="key-description">{description}</p>
            </div>
        </div >
    )
}

function Landing() {
    const { ga4 } = useContext(ApiContext);
    // FIXME:Text are copypasta from welcome information email, move to some common place
    return (
        < div className="landing-container" >
            <div className="landing-header-content">
                <div className="landing-header">
                    <div className="landing-header-text" >
                        <h1 className="landing-title">{shortTitle}</h1>
                        <p className="landing-sub-title">{description}</p>
                        <TodayStats />
                        <div className="supported-chargers-container">
                            <HashLink smooth className="supported-chargers" to="/#supported-chargers" scroll={(el) => {
                                console.log("Scroll to supported chargers")
                                el.scrollIntoView({ behavior: 'smooth' })
                                ga4.event({
                                    category: "device",
                                    action: "supported_chargers_link",
                                    label: "Supported chargers link clicked",
                                })
                            }}>Katso lista tuetuista latausasemista</HashLink>
                        </div>
                    </div>
                </div>
                <GetStarted />
            </div>
            <div className="landing-content" >
                <div className="landing-information" >
                    <KeyPoint icon={register} title="Rekisteröi latausasemasi" description="Rekisteröi OCPP-kykyinen latausasemasi palveluumme helposti. Tämän jälkeen palvelussa voit ohjata latausasemaa joko manuaalisesti tai automaattisesti ajastettuna. Rekisteröinnin voi myös helposti purkaa tarvittaessa." />
                    <KeyPoint icon={cheapestHours} title="Ajoita lataus halvimmille tunneille" description="Valitse hintakatto ja aktiivisten tuntien lukumäärä. Tämän jälkeen latausasemasi lataa joka päivä automaattisesti pörssisähkön halvimmilla tunneilla, jos hinta alittaa hintakaton. Asetusten asettamisen jälkeen sinun ei tarvitse tehdä mitään." />
                    <KeyPoint icon={timing} title="Kattavat asetukset" description="Voit valita automaattiseen ajastukseen aktiivisuusajan vuorokaudesta, josta halvimmat tunnit lasketaan. Esimerkiksi voit asettaa latausaseman lataamaan vain öisin, jolloin latausasema lataa yön halvimpina tunteina, vaikka päivällä olisikin ollut halvempaa sinä vuorokautena." />
                    <KeyPoint icon={safe} title="Turvallista" description="Latausaseman ohjaus perustuu avoimeen OCPP-standardiin kryptografisesti salatuilla TLS-tietoliikenneyhteyksillä. Latausasemasi vastaa varsinaisesta latauksen suorittamisesta." />
                    <div id="supported-chargers" className="landing-information landing-additional-information section-padding">
                        <h3 className="additional-info-title">Tuetut latausasemat</h3>
                        <p className="additional-info-description">Palvelussa toimivat kaikki latausasemat, jotka toteuttavat OCPP 1.6J -standardin. Valtaosa markkinoilla olevista älylatausasemista toteuttaa OCPP 1.6J-standardin. Hyvin todennäköistä, että jos latausasemassasi on internet-yhteys (WiFi/ethernet), on se myös yhteensopiva palvelumme kanssa.</p>
                        <p className="additional-info-description">Seuraavavien latausasemien rekisteröinnille löytyy palvelustamme ohjeet ja nämä on validoitu yhteensopiviksi:</p>

                        <ChargerSelection selectedCharger={null} setSelectedCharger={(e) => console.log(e)} showOther={false} />
                        <p className="additional-info-description">Eikö latausasemaasi näy listassa? Ota yhteyttä sähköpostilla <Link to="mailto:info@kajosolutions.com?subject=Uuden latausaseman lisäys&Body=Hei,%0D%0Ahaluaisin tuen latausasemalleni">info@kajosolutions.com</Link> ja validoidaan yhdessä latausasemasi yhteensopivuus.</p>
                        <h4 className="additional-info-subtitle">Open Charge Point Protocol (OCPP)</h4>
                        <p className="additional-info-description">Open Charge Point Protocol (OCPP) on avoin standardisoitu tiedonsiirtoprotokolla latausasemille. OCPP mahdollistaa latausaseman hallinnan turvallisesti keskitetystä palvelusta (Charging Station Management System, CSMS). </p>
                        <p className="additional-info-description">CSMS-palvelu voi esimerkiksi asettaa latausasemalle latausprofiilin, jolla lataamisen ajankohtaa voidaan hallita. Tällöin latauksesta vastaa kuitenkin aina paikallisesti latausasema ja CSMS-palvelusta saadaan vain korkean tason käyttösuunnitelma.</p>

                        <h3 className="additional-info-title section-padding">Kuinka Kajo Spotti toimii?</h3>
                        <h4 className="additional-info-subtitle">Käyttöönotto</h4>
                        <p className="additional-info-description"><Link to="/register">Rekisteröitymisen</Link> jälkeen voit lisätä latausasemasi <Link to="/device">Laitteet</Link>-sivulla. Latausaseman lisäämisen jälkeen löydät latausaseman OCPP-asetuksista ohjeet latausaseman yhdistämiseksi Kajo Spottiin.</p>
                        <p className="additional-info-description">Latausaseman yhdistämisen jälkeen laitteen tila näkyy Online-tilassa, jolloin voit hallita latausaseman toimintaa Spotista. Jos laitteen tilana ei ole Online, tarkista että latausasemalla on toimiva internet-yhteys ja seurasit ohjeita oikein.</p>
                        <h4 className="additional-info-subtitle sub-section-padding">Hallinta</h4>
                        <p className="additional-info-description">Latausaseman käyttöönoton jälkeen voit asettaa Spotissa latausaseman päälle, pois tai automaattiseen tilaan, jolloin lataus sijoittuu automaattisesti halvimmille tunneille ja säästät sähkölaskussa.</p>
                        <p className="additional-info-description">Automaattitila valitsee jokaisesta vuorokaudesta asetusten mukaisesti halvimmat tunnit ja ajoittaa latauksen näihin. Automaattitilaa voidaan ohjata seuraavilla asetuksilla: maksimihinta, lataustuntien määrä vuorokaudessa, latauksen aikaväli ja käytetäänkö peräkkäisiä tunteja.</p>
                        <h5 className="additional-info-subsubtitle">Maksimihinta</h5>
                        <p className="additional-info-description">Maksimihinnalla voit asettaa hintakaton, jonka haluat maksaa sähköstä enintään. Jos hinta ylittää tämän kynnyksen, ei ladata, vaikka tämä olisikin halvin tunti vuorokaudessa.</p>
                        <h5 className="additional-info-subsubtitle">Aktiivisten tuntien määrä</h5>
                        <p className="additional-info-description">Aktiivisten tuntien määrällä voidaan määrittää kuinka monta tuntia vuorokaudesta lataus on maksimissaan päällä. Vuorokaudesta valitaan tämän määrän tunteja halvimpien hintojen mukaan latauksen suorittamista varten.</p>
                        <h5 className="additional-info-subsubtitle">Aktiivisuuden aikaväli</h5>
                        <p className="additional-info-description">Aktiivisuuden aikavälillä voidaan tarkentaa ajankohtaa, josta halvimmat tunnit valitaan. Valitsemalla 0-24 halvimmat tunnit valitaan koko vuorokaudesta, kun taas valitsemalla 21-8 valitaan halvimmat tunnit yöstä iltayhdeksän ja aamukahdeksan väliltä.</p>
                        <h5 className="additional-info-subsubtitle">Peräkkäiset tunnit</h5>
                        <p className="additional-info-description">Valitsemalla peräkkäiset tunnit ladataan vain peräkkäisinä tunteina, jolloin vuorokaudesta lasketaan yhteensä halvin valittujen tuntien määrän mukainen halvin ajanjakso, jolloin lataus suoritetaan aina yhtajaksoisena ilman katkoksia.</p>
                        <h5 className="additional-info-subsubtitle">Demo</h5>
                        <p className="additional-info-description">Voit tutustua laitteiden hallintaan helposti ilman rekisteröitymistä <Link to="/sandbox">hiekkalaatikko</Link>-sivulla.</p>
                        <h4 className="additional-info-subtitle sub-section-padding">Laitteiden seuranta</h4>
                        <p className="additional-info-description"><Link to="/device">Laitteet</Link>-sivulla voit tarkastella lisäämiäsi laitteitasi, näiden tilaa, kuluvan vuorokauden lataussuunnitelmaa ja loppuun suoritettuja latauksia. Lisäksi laitteista esitetään arvioitu säästö kuluvalle vuorokaudelle, joka perustuu asetusten mukaiseen 11 kW teholla latauksen yhteishintaan verrattuna mediaanihintoihin ja maksimihintoihin kyseiseltä vuorokaudelta.</p>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Landing;