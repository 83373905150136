import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../ApiContext";
import "./Device.css";
import { Toaster, toast } from "react-hot-toast";

export default function DeviceSettings({ device, config, setConfig, enableSubmit = true }) {
  const { ApiCall, ga4 } = useContext(ApiContext);
  const [saved_config, set_saved_config] = useState();
  const [changes, set_changes] = useState(false);
  function saveSettings(config) {
    set_saved_config(config);
  }
  useEffect(() => {
    if (device && device.activeConfig) {
      saveSettings(config);
    }
  }, [device]);

  useEffect(() => {
    if (!saved_config) {
      set_changes(true)
      return
    }
    if (saved_config.consecutive !== config.consecutive) {
      set_changes(true)
    } else if (saved_config.lastHour !== config.lastHour) {
      set_changes(true)
    } else if (saved_config.firstHour !== config.firstHour) {
      set_changes(true)
    } else if (saved_config.numberOfHours !== config.numberOfHours) {
      set_changes(true)
    } else if (saved_config.maxPrice !== config.maxPrice) {
      set_changes(true)
    } else if (saved_config.mode !== config.mode) {
      set_changes(true)
    } else {
      set_changes(false)
    }
  }, [saved_config, config]);
  function handleSubmit(event) {
    event.preventDefault();
    if (!changes) {
      return
    }

    if (device.activeConfig) {
      const body = {
        ...config,
        mac: device.mac,
        id: device.activeConfig,
      };
      console.log("Updating existing config", body);
      ApiCall("PATCH", "device/config/", body).then((res) => {
        toast.success("Asetukset tallennettu")
        set_saved_config({ ...config })
        ga4.event({
          category: "device",
          action: "change_config",
          label: "Change device config",
        })
      }).catch((err) => {
        console.error(err)
      })
    } else {
      const body = {
        ...config,
        mac: device.mac,
        name: "default",
      };
      console.log("Creating new config: " + body);
      ApiCall("POST", "device/config/", body).then((res) => {
        ApiCall("POST", "device/config/activate", {
          mac: device.mac,
          id: res.id,
        }).then(() => {
          toast.success("Asetukset tallennettu")
          set_saved_config({ ...config })
          ga4.event({
            category: "device",
            action: "change_config",
            label: "Change device config",
          })
        }).catch((err) => {
          console.error(err)
        })
      }).catch((err) => {
        console.error(err)
      })
    }
  }

  function fixFirstHour(startHour) {
    if (startHour >= 24) {
      return 0;
    }
    return startHour;
  }

  //Server expects 0-23 (start of hour) but user writes 0-24 (end of hour)
  function fixLastHour(lastHour) {
    let result = lastHour - 1;
    if (result >= 23) {
      result = 23
    }
    if (result <= -1) {
      result = 23
    }
    return result;
  }

  const focus = (event) => event.target.select();

  return (
    <div className="device-info">
      <Toaster />
      <h2>
        Aktiivisuuden asetukset
      </h2>
      <form>
        <div className="settings-check-container">
          <label>
            <input type="radio" className="device-mode" value="on" checked={config.mode === "on"} onChange={(e) => setConfig({ ...config, mode: e.target.value })} />
            Aina päällä
          </label>
        </div>
        <div className="settings-check-container">
          <label>
            <input type="radio" className="device-mode" value="automatic" checked={config.mode === "automatic"} onChange={(e) => setConfig({ ...config, mode: e.target.value })} />
            Automaattinen
          </label>
        </div>
        <div className="settings-check-container">
          <label>
            <input type="radio" className="device-mode" value="off" checked={config.mode === "off"} onChange={(e) => setConfig({ ...config, mode: e.target.value })} />
            Pois päältä
          </label>
        </div>
      </form>
      <h3>Automaation asetukset</h3>
      <form onSubmit={handleSubmit}>
        <div className="settings-content">
          <div className="settings-row" >
            <p className="settings-label">Maksimihinta</p>
            <div className="settings-value">
              <input
                className="settings-cell-number-input"
                type="number"
                required={true}
                value={config.maxPrice}
                onFocus={focus}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    maxPrice: parseInt(e.target.value),
                  })
                }
              />
              <p className="settings-unit">
                snt/kWh
              </p>
            </div>
          </div>
          <div className="settings-row" >
            <p className="settings-label">Aktiivisten tuntien määrä</p>
            <div className="settings-value">
              <input
                className="settings-cell-number-input"
                type="number"
                min={0}
                max={24}
                required={true}
                value={config.numberOfHours}
                onFocus={focus}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    numberOfHours: parseInt(e.target.value),
                  })
                }
              />
              <p className="settings-unit">
                kpl/vrk
              </p>
            </div>
          </div>
          <div className="settings-row" >
            <p className="settings-label">Aktiivisuuden aikaväli</p>
            <div className="settings-value">
              <input
                className="settings-time-input"
                type="number"
                required={true}
                min={0}
                max={24}
                value={config.firstHour}
                onFocus={focus}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    firstHour: fixFirstHour(parseInt(e.target.value)),
                  })
                }
              /><p className="settings-time-unit">.00</p>
              <p className="settings-time-divider">-</p>
              <input
                className="settings-time-input"
                type="number"
                required={true}
                min={0}
                max={24}
                value={config.lastHour + 1}
                onFocus={focus}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    lastHour: fixLastHour(parseInt(e.target.value)),
                  })
                }
              /><p className="settings-time-unit">.00</p>
            </div>
          </div>
        </div>
        <div className="settings-check-container">
          <label>
            <input
              type="checkbox"
              checked={config.consecutive}
              onChange={(e) => {
                setConfig({
                  ...config,
                  consecutive: e.target.checked,
                });
              }}
            />
            Peräkkäiset tunnit
          </label>
        </div>
        {enableSubmit && <input type="submit" value="Tallenna" className={changes ? "basic-button" : "basic-button disabled"} style={{ marginBottom: "1em" }} />}
      </form >
    </div >
  );
}
