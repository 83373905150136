import { useContext, useEffect, useState } from "react";
import { ApiContext } from "../ApiContext";
import { ColorScheme } from "../utils/ColorScheme";
import Loading from "./Loading";
import { ReactComponent as ArrowLeft } from "../assets/icons/arrow-left.svg";
import "./Device.css";

export default function DeviceLog(props) {
  const { ApiCall } = useContext(ApiContext);
  const [log, set_log] = useState();
  const lastOnline = new Date(props.device.lastOnline);
  const now = new Date();
  const delta = now - lastOnline;
  const online = delta <= 5 * 60 * 1000;

  useEffect(() => {
    ApiCall("GET", "device/log/" + props.device.mac + "?limit=9").then(
      (res) => {
        set_log(res);
      }
    ).catch((err) => {
      console.error(err)
    })
  }, [ApiCall, props.device]);
  function renderOcppDetails(entry) {
    const chargedKwh = Math.round(entry.meterStop / 100) / 10
    return <>
      <p className="device-log-entry">{chargedKwh} kWh ladattu</p>
    </>
  }

  function renderLog() {
    if (log.length > 0) {
      return (
        <div>
          {log.map((entry) => {
            return (
              <div key={entry.id} style={{ display: "flex" }}>
                <p>{new Date(entry.createdAt).toLocaleString("fi-FI", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })}</p>
                {entry.newState !== null && <ArrowLeft
                  className={entry.newState ? "device-on" : "device-off"}
                />}
                {entry.meterStop !== null && renderOcppDetails(entry)}
                {entry.reason !== null && <p className="device-log-entry">{entry.reason}</p>}
              </div>
            );
          })}
        </div>
      );
    } else {
      return <p>Ei vielä tapahtumia</p>;
    }
  }
  const validLastOnline = lastOnline.getTime() > 0;
  return (
    <div className="device-info" style={{ paddingBottom: "0.5em" }}>
      <h2>
        Laitteen tila
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {online ? (
          <p style={{ color: ColorScheme.primary_green }}>Online</p>
        ) : (
          <p style={{ color: ColorScheme.error }}>Offline</p>
        )}
        {/*
        {props.device.status ? (
          <p style={{ color: ColorScheme.primary_green }}>Aktiivinen</p>
        ) : (
          <p style={{ color: ColorScheme.dark_grey }}>Inaktiivinen</p>
        )}
        */}
      </div>
      {validLastOnline && <p>Viimeksi nähty {lastOnline.toLocaleString("fi-FI")}</p>}
      {!validLastOnline && <p>Laite ei ole yhdistänyt palveluun</p>}

      <h3>
        Viimeiset tapahtumat
      </h3>
      {!log && <Loading />}
      {log && renderLog()}
    </div>
  );
}
