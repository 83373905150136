import { NavLink } from "react-router-dom";
import { ColorScheme } from "../utils/ColorScheme";

export default function Footer() {

    return (
        <div style={{
            width: "100%",
            alignItems: "center",
            display: "flex",
            padding: "40px",
            boxSizing: "border-box",
            justifyContent: "center",
        }}>
            <h5 style={{ margin: "0px" }}>{"© " + new Date().getFullYear() + " Kajo Solutions Oy "}<NavLink to="/about" style={{ fontSize: "1em" }}>Ota yhteyttä</NavLink></h5>
        </div>
    );
}
