export const ColorScheme = {
  bg_color: "#EBF2ED",
  primary_green: "#80D43E",
  primary_green_shade: "#80D43E88",
  white: "#FFFFFF",
  black: "rgb(34,34,34)",
  error: "rgb(255, 30, 30)",
  dark_grey: "#878787",
  light_grey: "#bbbbbb",
  light_grey_shade: "#bbbbbb88",
};
