import React, { useEffect, useState, useContext } from "react";
import { ApiContext } from "../ApiContext";
import PriceGraph from "./PriceGraph";
import "./Device.css";
import { ColorScheme } from "../utils/ColorScheme";
import { motion } from "framer-motion";
import { calculateSavingsToday } from "../utils/SavingsCalculation";

const VAT_PERCENTAGE = require("spot-automation-common").VAT_PERCENTAGE

export default function DeviceStatus({ config, details = true, showTicks = false, showTitle = true }) {
  const { ApiCall, todayPrices } = useContext(ApiContext);

  const [dayPrices, setDayPrices] = useState()
  const [activity, setActivity] = useState()
  const [nextActivity, setNextActivity] = useState()
  const [animate, setAnimate] = useState(false)
  const [savingsToday, setSavingsToday] = useState()

  useEffect(() => {
    if (activity) {
      const now = new Date()
      now.setMinutes(0)
      now.setSeconds(0)
      now.setMilliseconds(0)
      var last = now
      var startOfNextActivity = undefined
      var endOfNextActivity = undefined
      console.log("Activity", activity)
      // Have to iterate activity becuase prices does not contain tomorrow data
      for (var i = 0; i < activity.length; i++) {
        const it = activity[i].date
        if (it < now) {
          last = it
          continue
        }
        const secondsSinceLast = (it - last) / 1000
        if (secondsSinceLast <= 3600) {
          // Still the current active period
          last = it
          continue
        }
        else if (startOfNextActivity) {
          endOfNextActivity = new Date(last)
          endOfNextActivity.setTime(endOfNextActivity.getTime() + 60 * 60 * 1000)
          break;
        }
        else {
          startOfNextActivity = it
          last = it
        }
      }
      if (startOfNextActivity && !endOfNextActivity) {
        // Start set but no gap found, ended on last activity
        endOfNextActivity = new Date(activity[activity.length - 1].date)
        endOfNextActivity.setTime(endOfNextActivity.getTime() + 60 * 60 * 1000)
      }
      if (startOfNextActivity && endOfNextActivity) {
        console.log(`Setting next activity start ${startOfNextActivity}, end ${endOfNextActivity}`)
        setNextActivity({
          start: startOfNextActivity,
          end: endOfNextActivity
        })
      }
      else {
        console.log("Clearing next activity")
        setNextActivity(undefined)
      }
    }
  }, [activity])

  useEffect(() => {
    console.log("Check new savings")
    if (activity && todayPrices && dayPrices) {
      setSavingsToday(calculateSavingsToday(todayPrices, activity))
    }
  }, [activity, todayPrices, dayPrices])

  useEffect(() => {
    if (todayPrices && activity && dayPrices) {
      setAnimate(true)
    }
  }, [dayPrices, activity, todayPrices])

  function renderNextActivity() {
    if (config.mode !== "automatic") {
      return <div>
        <p style={{ fontWeight: "900" }}>{config.mode === "on" ? "Aina päällä" : "Pois päältä"}</p>
      </div>
    }
    else {
      var activityTxt = "ei tiedossa"

      if (nextActivity && nextActivity.start.getDate() === new Date().getDate()) {
        activityTxt = `klo ${nextActivity.start.getHours()}-${nextActivity.end.getHours()}`
      }
      else if (nextActivity) {
        activityTxt = `huomenna klo ${nextActivity.start.getHours()}-${nextActivity.end.getHours()}`
      }

      return <div style={{ display: "flex", flexDirection: "row" }}>
        <p>Seuraava aktiivisuus</p>
        <p style={{ fontWeight: "900", marginLeft: "0.3em" }}>{activityTxt}</p>
      </div>
    }
  }

  function isActive(val) {
    if (activity) {
      for (let i = 0; i < activity.length; i++) {
        if (val.getDate() === activity[i].date.getDate() && val.getHours() === activity[i].date.getHours()) {
          return true;
        }
      }
    }
    return false;
  }

  function isIndexDisabled(index) {
    if (config.mode !== "automatic") {
      return false;
    }
    if (config.firstHour > config.lastHour) {
      if (index % 24 < config.firstHour && index % 24 > config.lastHour) {
        return true;
      }
    } else {
      if (index % 24 < config.firstHour || index % 24 > config.lastHour) {
        return true;
      }
    }
    return false;
  }

  function formatSavings(savings) {
    const style = { display: "flex", flexDirection: "row" }
    const className = config.mode == "automatic" ? "visible" : "invisible"
    const prefix = "Arvioitu säästö"
    if (!savings) {
      return <></>
    }
    if (savings.max >= 100) {
      return <div style={style} className={className}>
        <p>{prefix}</p>
        <p style={{ fontWeight: "900", marginLeft: "0.3em" }}>{(savings.median / 100).toFixed(2)}</p>
        <p style={{ marginLeft: "0.4em" }}>–</p>
        <p style={{ fontWeight: "900", marginLeft: "0.4em" }}>{(savings.max / 100).toFixed(2)}</p>
        <p style={{ color: ColorScheme.primary_green, marginLeft: "0.3em" }}>€</p>
      </div>
    }
    else {
      return <div style={style} className={className}>
        <p>{prefix}</p>
        <p style={{ fontWeight: "900", marginLeft: "0.3em" }}>{(savings.median).toFixed(0)}</p>
        <p style={{ marginLeft: "0.4em" }}>–</p>
        <p style={{ fontWeight: "900", marginLeft: "0.4em" }}>{(savings.max).toFixed(0)}</p>
        <p style={{ color: ColorScheme.primary_green, marginLeft: "0.3em" }}>snt</p>
      </div>
    }
  }

  useEffect(() => {
    console.log(`Gettings devices status for  ${JSON.stringify(config)}`);
    if (isNaN(config.firstHour) || isNaN(config.lastHour) || isNaN(config.numberOfHours) || isNaN(config.maxPrice)) {
      console.log("Invalid config")
    }
    else {
      ApiCall("POST", "device/usage", config).then((res) => {
        const act = res.map(it => ({
          date: new Date(it.date),
          price: (it.price * (100 + VAT_PERCENTAGE))
        }))
        act.sort((a, b) => a.date.getTime() - b.date.getTime())
        setActivity(act)
      }).catch((err) => {
        console.error(err)
      })
    }
  }, [config])

  const now = new Date();
  useEffect(() => {
    if (todayPrices && !dayPrices) {
      let min = 99999;
      let max = 0;
      let current = 0;
      let average = 0;
      for (let i = 0; i < todayPrices.length; i++) {
        let price = todayPrices[i].price;
        average += price
        if (price > max) {
          max = price;
        }
        if (price < min) {
          min = price;
        }
        if (todayPrices[i].date.getDate() === now.getDate() && todayPrices[i].date.getHours() === now.getHours()) {
          current = price;
        }
      }
      average = average / todayPrices.length
      setDayPrices({ min: min, max: max, avg: average, current: current });
    }
  }, [todayPrices]);
  const highlightedIndices = []
  const disabledIndices = []
  if (todayPrices) {
    todayPrices.map((v, i) => {
      if (isActive(v.date)) {
        highlightedIndices.push(i)
      }
      if (isIndexDisabled(i)) {
        disabledIndices.push(i)
      }
    })
  }

  if (!todayPrices || !activity || !dayPrices) {
    return <></>;
  } else {
    return (
      <motion.nav
        initial={{ opacity: 0 }}
        animate={{ opacity: animate ? 1 : 0 }}
        transition={{ ease: "linear", duration: 0.3, delay: 0.1 }}
        style={{ width: "100%" }}>
        {showTitle && <h2>Aktiivisuus tänään</h2>}
        <div className="device-graph-container">
          <div className="device-graph">
            <PriceGraph
              prices={todayPrices}
              showTicks={showTicks}
              showDate={false}
              highlightedIndices={highlightedIndices}
              disabledIndices={disabledIndices}
            />
          </div>
          <div className="device-status-info">
            <div style={{ display: "flex", flexDirection: "row" }}>
              {isActive(new Date()) ? <p style={{ color: ColorScheme.primary_green, fontWeight: "900", width: "100%" }}>Aktiivinen</p> : <p style={{ color: ColorScheme.light_grey, fontWeight: "900", width: "100%" }}>Inaktiivinen</p>}
            </div>
            {details &&
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p>Hinta nyt</p>
                <p style={{ fontWeight: "900", marginLeft: "0.3em" }}>{dayPrices.current.toFixed(2)}</p>
                <p style={{ color: ColorScheme.primary_green, marginLeft: "0.3em" }}>snt/kWh</p>
              </div>
            }
            {details && formatSavings(savingsToday)}
            {details && renderNextActivity()}
          </div>
        </div>
      </motion.nav >
    );
  }
}
