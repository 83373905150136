import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../ApiContext";
import "./Device.css";
import Select from 'react-select'
import { ColorScheme } from "../utils/ColorScheme";
import Loading from "./Loading";

function ChargerSelection({ selectedCharger, setSelectedCharger, selectedGuide = undefined, setSelectedGuide = (guide) => { }, showOther = true }) {
    const { ApiCall } = useContext(ApiContext)
    const [supportedChargers, setSupportedChargers] = useState()
    const [valueToGuideMap, setValueToGuideMap] = useState({})
    const [defaultValue, setDefaultValue] = useState(showOther ? { value: null, label: "Muu" } : undefined)
    useEffect(() => {
        if (supportedChargers) {
            return
        }
        ApiCall("GET", "device/charger/types").then(res => {
            const valueToGuideMapBuilder = {}
            const groupByManufacturer = {}
            res.map(supportedCharger => {
                const manufacturer = supportedCharger.manufacturer
                const name = supportedCharger.name
                const id = supportedCharger.id
                const guide = supportedCharger.guide
                if (!groupByManufacturer[manufacturer]) {
                    groupByManufacturer[manufacturer] = []
                }
                groupByManufacturer[manufacturer].push({ name: manufacturer + " - " + name, id: id, guide: guide })
            })
            let options = []
            for (const manufacturer in groupByManufacturer) {
                const manufacturerOptions = []
                groupByManufacturer[manufacturer].map(details => {
                    const option = { value: details.id, label: details.name };
                    valueToGuideMapBuilder[details.id] = details.guide
                    if (selectedCharger === option.value) {
                        setDefaultValue(option)
                        setSelectedCharger(option.value)
                    }
                    manufacturerOptions.push(option)
                })
                options.push({ label: manufacturer, options: manufacturerOptions })
            }
            //TODO: sort?
            if (showOther) {
                options.push({ label: "Muu", options: [{ value: null, label: "Muu" }] })
            }
            console.log(valueToGuideMapBuilder)
            setValueToGuideMap(valueToGuideMapBuilder)
            setSupportedChargers(options)
        })
    }, [supportedChargers])

    const Group = (props) => (
        <div>
            <components.Group {...props} />
        </div>
    );
    return (
        <div style={{ width: "100%", marginBottom: "1em", marginTop: "1em" }}>
            {supportedChargers &&
                <Select
                    options={supportedChargers}
                    styles={{
                        control: (base) => ({
                            ...base,
                            background: "#0000000d",
                            border: "none"
                        }),
                    }}
                    required={true}
                    placeholder="Hae latausaseman tyyppi"
                    noOptionsMessage={() => "Ei tuloksia"}
                    components={Group}
                    className="selection"
                    classNamePrefix="selection"
                    defaultValue={defaultValue}
                    onChange={event => {
                        setSelectedCharger(event.value)
                        const guide = valueToGuideMap[event.value]
                        setSelectedGuide(guide)
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: "10px",
                        colors: {
                            ...theme.colors,
                            primary25: ColorScheme.primary_green,
                        },
                    })}
                />}
            {!supportedChargers && <Loading height={50} />}
        </div>
    )
}

export default ChargerSelection;