import React, { useContext, useState } from "react";
import { ReactComponent as Gear } from "../assets/icons/gear.svg";
import { ApiContext } from "../ApiContext";
import { motion } from "framer-motion";
import "./Device.css";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { popupEasing } from "../utils/Easing";

function DeviceOptions({ closeOptions, device, setDevice }) {
  const { ApiCall, ga4 } = useContext(ApiContext)
  const [deviceName, setDeviceName] = useState(device.displayName)
  const [deleteConfirmed, setDeleteConfirmed] = useState(false)
  const navigate = useNavigate()

  function handleSubmit(event) {
    event.preventDefault();
    if (!hasChanges()) {
      return
    }
    ApiCall("POST", "device", {
      mac: device.mac,
      displayName: deviceName,
      chargerType: device.chargerType,
    }).then((res) => {
      closeOptions()
      toast.success("Asetukset tallennettu")
      setDevice(res);
      ga4.event({
        category: "device",
        action: "change_settings",
        label: "Change device general settings",
      })
    }).catch((err) => {
      console.error(err)
    })
  }
  function handleDelete(event) {
    event.preventDefault();
    if (!deleteConfirmed) {
      return
    }
    ApiCall("DELETE", "device", {
      mac: device.mac,
    }).then(() => {
      closeOptions();
      ga4.event({
        category: "device",
        action: "delete",
        label: "Delete device or charger",
      })
      navigate("/device")
    }).catch((err) => {
      console.error(err)
    })
  }


  function hasChanges() {
    return deviceName !== device.displayName
  }
  return (
    <motion.nav
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: "linear", duration: 0.2 }}
      className="device-setup-nav"
    >
      <Toaster />
      <div onClick={() => closeOptions()} className="device-setup-container">
        <motion.nav
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ ease: popupEasing, duration: 0.2 }}
          className="device-setup-nav"
        >
          <div onClick={(e) => e.stopPropagation()} className="popup-container input-container">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
              <h2>
                Laitteen asetukset
              </h2>
              <button className="circle-button" onClick={() => closeOptions()} style={{ marginLeft: "1em" }}>
                <Gear />
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Nimi"
                required={true}
                value={deviceName}
                maxLength={256}
                onChange={(e) => setDeviceName(e.target.value)}
              />
              <input type="submit" value="Tallenna" className={hasChanges() ? "basic-button" : "basic-button disabled"} />
            </form>
            <h3> Laitteen poisto </h3>
            <form onSubmit={handleDelete}>
              <div style={{ width: "100%", display: "flex" }}>
                <input type={"checkbox"} value={deleteConfirmed} required={true} onChange={(e) => {
                  const value = e.target.checked
                  console.log(value)
                  setDeleteConfirmed(value)
                }}></input>
                <label className="confirm">Vahvista laitteen poisto</label>
              </div>
              <input type="submit" value="Poista laite" className={deleteConfirmed ? "basic-button" : "basic-button disabled"} />
            </form>
          </div>
        </motion.nav>
      </div>
    </motion.nav>
  );
}

export default DeviceOptions;
