import React, { useContext, useState } from "react";
import { ApiContext } from "../ApiContext";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";

export default function DeleteAccount() {
    const navigate = useNavigate();

    const { ApiCall, logout, ga4 } = useContext(ApiContext);

    const [password, setPassword] = useState("");

    function handleSubmit(event) {
        event.preventDefault();
        ApiCall("DELETE", "user/deleteUser", {
            password: password,
        })
            .then(() => {
                toast.success("Käyttäjätilisi on poistettu")
                setTimeout(() => {
                    logout()
                    navigate("/")
                }, 5000)
                ga4.event({
                    category: "user",
                    action: "delete_user",
                    label: "User deleted",
                })
            })
            .catch((err) => {
                console.error(err);
                toast.error("Väärä salasana");
            });
    }

    return (
        <div className="main-content">
            <Toaster />
            <div className="input-container">
                <div>
                    <h3>Käyttäjätilin poisto</h3>
                </div>
                <form onSubmit={handleSubmit}>
                    <label>Syötä salasanasi vahvistaaksesi poisto</label>
                    <input
                        type="password"
                        required={true}
                        minLength={8}
                        autoComplete="new-password"
                        placeholder="Salasana"
                        id="password"
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                    ></input>
                    <label>Tilin poistaminen on lopullista</label>
                    <div style={{ display: "flex", alignItems: "center", width: "100%", marginTop: "1em" }}>
                        <input type="checkbox" required={true} />
                        <label style={{ marginBottom: "0px" }}>Vahvista tilin poisto</label>
                    </div>
                    <input type="submit" value="Poista käyttäjätili" className="basic-button" />
                </form>
            </div>
        </div >
    );
}
